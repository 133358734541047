@media print {
	.print-layout {
		display: flex;
		flex-direction: column;
		gap: 40px; /* Add spacing between elements */
		align-items: center;
		width: 100%;
		margin: 10px auto;
	}

	.print-pie-chart {
		display: block;
		width: 100%; /* Ensure full width for charts */
		height: 400px; /* Set an explicit height for the pie chart */
		margin: 20px auto; /* Add spacing above and below */
		page-break-inside: avoid; /* Prevent splitting the chart across pages */
	}

	.print-bar-chart {
		display: block;
		width: 100%; /* Ensure full width for charts */
		height: 400px; /* Set an explicit height for the bar chart */
		margin: 340px 0px 0px 0px;
		page-break-inside: avoid;
		position: relative;
		top: 16vh;
	}

	@page {
		margin: 1cm; /* Adjust page margins for better spacing */
	}
}

@media (min-width: 992px) and (max-width: 1115px) {
	.generate-report-button {
		font-size: 60%; /* Adjust this size as needed */
	}
}

@media (min-width: 826px) and (max-width: 1050px) {
	.provider-specific {
		position: relative;
		top: 10%; /* Adjust as per your requirement */
		left: 2%; /* Adjust as per your requirement */
		text-align: center; /* Optional */
	}
}

@media (min-width: 980px) and (max-width: 1050px) {
	.provider-specific-container {
		position: relative;
		top: 10%; /* Adjust as per your requirement */
		left: 0.2%; /* Adjust as per your requirement */
		text-align: center; /* Optional */
	}
}

@media (min-width: 826px) and (max-width: 979px) {
	.move-chart {
		position: relative;
		right: 20%;
	}
}

@media (min-width: 826px) and (max-width: 979px) {
	.move-value {
		position: relative;
		left: 5%;
	}
}

@media (min-width: 768px) and (max-width: 825px) {
	.mini-chart-move {
		position: relative;
		right: 15%;
	}
}

@media (min-width: 768px) and (max-width: 825px) {
	.mini-text-move {
		position: relative;
		left: 2%;
	}
}

@media (min-width: 768px) and (max-width: 825px) {
	.mini-percentage-move {
		position: relative;
		top: 17px;
	}
}

@media (min-width: 676px) and (max-width: 767px) {
	.micro-chart-move {
		position: relative;
		right: 20%;
	}
}

@media (min-width: 676px) and (max-width: 767px) {
	.micro-text-move {
		position: relative;
		left: 12%;
	}
}

@media (min-width: 676px) and (max-width: 767px) {
	.micro-percentage-move {
		position: relative;
		left: 7%;
		top: 20px;
		margin-bottom: 15%;
	}
}
