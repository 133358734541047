@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* add the code bellow */ 
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}


/* custom.css */
::placeholder {
  font-family: "Poppins";
  font-size: smaller;
  color: #737791 !important;
  /* Change this to your desired color */
}

.table-header-bg-white .ant-table-thead>tr>th {
  background-color: white !important;
}

/* custom.css */
.custom-btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  /* Equivalent to text-xs */
  width: 80%;
  padding: 0.5rem 1rem;
  /* Equivalent to px-1 py-2 */
  letter-spacing: 0.05em;
  /* Equivalent to tracking-wider */
  color: #458ff6;
  background-color: white;
  border: 1px solid rgba(182, 167, 167, 0.492) !important;
  border-radius: 9999px;
  /* Equivalent to rounded-full */
  box-shadow: 0 4px 6px rgb(255, 255, 255) !important;
}

.custom-btn:hover {
  background-color: white !important;
  color: #458ff6 !important;
  box-shadow: 0 4px 6px rgb(255, 255, 255) !important;
  border: 1px solid white !important;
}

/* Custom scrollbar styles */
.custom-table .ant-table-body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.custom-table .ant-table-body::-webkit-scrollbar-thumb {
  /* background-color: #ff0f0f; */
  background-color: #888; /* Default scrollbar thumb color */
  border-radius: 10px;
}

.custom-table .ant-table-body::-webkit-scrollbar-thumb:hover {
  /* background-color: #ef0000; */
  background-color: #555; /* Hover color for scrollbar thumb */
}

.custom-table .ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
  /* background: #D9D9D9; */
  border-radius: 30px;
}

/* For Firefox */
.custom-table .ant-table-body {
  scrollbar-width: thin;
  scrollbar-color: #D9D9D9 #ffffff;
}

.ant-menu-item {
  padding-right: 0 !important; 
}


.ant-menu-item-selected {
  background-color: #1890ff !important;
  /* Change this color to your desired selected background color */
  color: white !important;
  /* Change this color to your desired selected text color */
  font-family: 'Poppins';
}

.custom-select .ant-select-selector {
  border: 3px solid #f9fafb !important;
  /* border: 1px solid red ; */
  border-radius: 12px !important;
}

.custom-select .ant-select-arrow {
  font-size: 10px;
  color: #458FF6 !important;
}


.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* Custom styles for carousel arrows */
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: #1677ff;
  /* Change to your desired color */
  z-index: 1;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  color: #1677ff;
  /* Change to your desired hover color */
}

/* Custom styles for carousel dots (tabs) */
.ant-carousel .slick-dots li button {
  background: #1677ff;
  /* Change to your desired dot color */
}

.ant-carousel .slick-dots li.slick-active button {
  background: #1677ff;
  /* Change to your desired active dot color */
}

.ant-carousel .slick-dots li button:hover {
  background: #1677ff;
  /* Change to your desired hover dot color */
}




.custom-range-picker .ant-picker-input input::placeholder {
  color: #737791 !important;
  /* Change color to red */
  font-family: "Poppins" !important;

}

/* Custom styles for RangePicker icons */
.custom-range-picker .ant-picker-suffix,
.custom-range-picker .ant-picker-clear {
  color: #3B82F6;
  /* Change the color to blue or any other color you prefer */
}



/* Custom styles for the arrow in RangePicker */
.custom-range-picker .ant-picker-range-separator .ant-picker-separator {
  color: #3B82F6 !important;
  /* Change the color to blue or any other color you prefer */
}


.custom-placeholder::placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}

/* .ant-picker-ok button {
  display: none !important;
} */


/* Custom styles for the collapsible icon at the bottom */
.sider-collapsible-trigger .anticon {

  color: black;
  /* Change this color to any desired color */
}

/* You might also need to override Ant Design styles if necessary */
.sider-collapsible-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #1890ff; */
  background-color: white;
  padding: 17px;
}

.custom-date-picker {
  width: 80%;
  /* Adjust width if necessary */
  border: 3px solid #f9fafb !important;
  /* border: 1px solid #d9d9d9; */
  border-radius: 12px !important;
  padding: 5px;
  /* Padding for the input field */
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  color: #737791;
  /* Match the placeholder text color */
  background-color: #fff;
  transition: all 0.3s ease;
  /* Smooth transitions */
}

.custom-date-picker:hover {
  border-color: #458ff6;
  /* Change border color on hover */
  box-shadow: 0 0 5px rgba(69, 143, 246, 0.4);
  /* Subtle shadow on hover */
}

.custom-date-picker:focus-within {
  border-color: #458ff6;
  /* Focus border */
  box-shadow: 0 0 5px rgba(69, 143, 246, 0.6);
  /* Focus shadow */
}

.custom-date-picker .ant-picker-input>input {
  color: #737791;
  /* Placeholder color */
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.custom-switch :where(.css-dev-only-do-not-override-zg0ahe) .ant-switch .ant-switch-checked {
  background: #4b5bff !important;
  /* Blue color for the enabled state */
}

/* Custom styling for the switch when toggled off (unchecked) */
.custom-switch {
  background-color: #d1d5db;
  /* Gray color for the disabled state */
}

/* table header */
.custom-ant-table-design .ant-table-thead>tr>th {
  /* Light blue background */
  background-color: #458ff6 !important;
  /* White text */
  color: white !important;
  /* font-weight: bold !important; */
  /* Optional: make text bold */
}

/* table header vertical line */
.custom-ant-table-design .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 2px;
  height: 100%;
}

/* table filter icon */
.custom-ant-table-design .ant-table-thead>tr>th .anticon-filter svg {
  color: white
}

/* table sort icon */
.custom-ant-table-design .ant-table-wrapper .ant-table-column-sorter-inner {
  color: white
}

/* table row stripped color for even*/
.custom-ant-table-design .ant-table-tbody>tr:nth-child(even) {
  background-color: rgb(236, 244, 254);
}

/* table row stripped color for odd*/
.custom-ant-table-design .ant-table-tbody>tr:nth-child(odd) {
  background-color: rgba(236, 244, 254, 0.28);
}

/* table each row each cell border */
.custom-ant-table-design .ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: 6px solid white !important;
  border-right: 2px solid white !important;
}

/* table search icon */
.custom-ant-table-design .ant-table-thead>tr>th .anticon-search svg {
  color: white
}

.custom-select-placeholder .ant-select-selection-placeholder {
  color: #6c757d;
  opacity: 0.6;
  font-size: 14px;
  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  ;
}

.custom-placeholder input::placeholder {
  color: #6c757d !important;
  /* Custom color */
  opacity: 0.6 !important;
  /* Adjust opacity */
  font-size: 14px !important;
  /* Font size */
  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  /* Custom font */
}

.custom-placeholder::placeholder {
  color: #6c757d !important;
  opacity: 0.6 !important;
  font-size: 14px !important;
  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

/* Start Global Scrollbar Customization */

/* Webkit browsers (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  /* Set width for vertical scrollbar */
  width: 8px;
  /* Set height for horizontal scrollbar */
  height: 10px;
}

::-webkit-scrollbar-track {
  /* Background color for the scrollbar track */
  background: #f1f1f1;
  /* Rounded corners for the track */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  /* Color of the scrollbar thumb */
  background-color: #D9D9D9;
  /* Rounded corners for the thumb */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  /* Color when hovering over the scrollbar thumb */
  background-color:  #B0B0B0;
  /* Ensure hover state also has rounded corners */
  border-radius: 5px;
}

.ant-table-wrapper .ant-table {
  scrollbar-color: auto
}

/* End Global Scrollbar Customization */

/* Start Global Content Background Color */
.ant-layout {
  background-color: white;
}

/* End Global Content Background Color */



.ant-select.custom-select .ant-select-selector {
  cursor: pointer !important; /* Override the cursor */
}

@media (min-width: 768px) and (max-width: 991px) {
  .center-image {
    margin-top: 2%;
    height: 50vh;
    width: 50vw;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .center-image-small {
    margin-top: 10%;
    height: 50vh;
    width: 50vw;
  }
}


/* need a check on this */
@media (min-width: 768px) and (max-width: 991px) {
  .center-image-provider {
    height: 50vh;
    width: 50vw;
  }
}
/* border when expanded */
.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-inline > .ant-menu-item {
  border-inline-end: none !important; /* Overwrites the default property */
}

/* border when collapsed */
.ant-menu-inline, 
.ant-menu-vertical {
  border-inline-end: none !important;
}

/* .ant-menu-item {
  transition: all 0.3s ease;
} */

/* .ant-menu-item:hover {
  background-color: #ECF4FE !important;

}

.ant-menu-item-active {
  background-color: transparent !important;
}

.ant-menu-item-selected:hover {
  background-color: inherit !important;
  color: inherit !important; 
}

.ant-menu-submenu-title:hover {
  background-color: #ECF4FE !important;
} */


/* Hover styling for non-selected items */
.ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #ECF4FE !important; /* Hover color for non-selected items */
}

/* Keep the same styling for selected items when hovered */
.ant-menu-item-selected:hover {
  background-color: #1890FF !important; /* Same background color as the selected state */
  color: white !important; /* Same text color as the selected state */
}

/* For submenu titles */
.ant-menu-submenu-title:not(.ant-menu-submenu-selected):hover {
  background-color: #ECF4FE !important; /* Hover color for non-selected submenu titles */
}

.ant-menu-submenu-title.ant-menu-submenu-selected:hover {
  background-color: #1890FF !important; /* Same background color as the selected state */
  color: white !important; /* Same text color as the selected state */
}
